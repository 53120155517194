import React, { useEffect, useState, useRef } from "react"
import classNames from "classnames"

import { Swiper, SwiperSlide } from "swiper/react"

import styles from "./styles.module.scss"

import { getData } from "./data"

import { useLocalizationContext } from "../../../localization/useLocalizationContext";
import pageStyles from "../../../styles/_V2/pagesStyles/styles.module.scss";

interface Props {
	className?: string // Класс
}

export default function Diagram(props: Props) {
    const localizationContext = useLocalizationContext()

    const data = getData(localizationContext.locale);

	const scrollContainerRef = useRef(null)
	const [isOnSection, setIsOnSection] = useState(false)

	const onMouseEnter = () => {
		setIsOnSection(true)
	}
	const onMouseLeave = () => {
		setIsOnSection(false)
	}

	const mouseMoveHandler = (evt: any) => {
		if (isOnSection && scrollContainerRef.current) {
			const containerOffsetLeft =
				evt.clientX - (scrollContainerRef.current as any).offsetLeft // Скролл с отступом от края document

			// @ts-ignore
			scrollContainerRef.current.scrollLeft = containerOffsetLeft / 4
		}
	}

	useEffect(() => {
		scrollContainerRef.current &&
			(scrollContainerRef.current as any).addEventListener(
				"mousemove",
				mouseMoveHandler
			)

		return () =>
			(scrollContainerRef.current as any).removeEventListener(
				"mousemove",
				mouseMoveHandler
			)
	}, [scrollContainerRef, isOnSection])

	return (
		<section
			className={classNames(styles.section, props.className)}
			onMouseLeave={onMouseLeave}
		>
			<h2 className={styles.heading}>Наш путь</h2>

			<div
				className={styles.diagram__container}
				ref={scrollContainerRef}
				onMouseEnter={onMouseEnter}
			>
				<div className={styles.diagram__row}>
					{data.map(item => (
						<div key={item.year} className={styles.diagram__cell}>
							<div className={styles.diagram__info}>
								{item.mediaContent}
								<span>{item.textarea}</span>
							</div>

							<span className={styles.diagram__date}>{item.year}</span>
						</div>
					))}

					<div className={classNames(
                        styles.diagram__cell,
                        styles.diagram__cell_empty
                    )}></div>
                    <div className={classNames(
                        styles.diagram__cell,
                        styles.diagram__cell_empty
                    )}></div>
				</div>
			</div>

			<Swiper
				slidesPerView={1.5}
				centeredSlides={true}
				grabCursor={true}
				autoHeight={true}
				freeMode={true}
				className={styles.slider}
			>
				{data.map((card, index) => (
					<SwiperSlide key={card.year}>
						<div
							className={classNames(styles.card, {
								[styles.card__separator]: index !== data.length - 1,
							})}
						>
							{card.mediaContent}
							<span className={styles.card__date}>{card.year}</span>
							<span className={styles.card__content}>{card.textarea_m}</span>
						</div>
					</SwiperSlide>
				))}
			</Swiper>
		</section>
	)
}
