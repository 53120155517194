import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"

export default {
	mediaContent: (
		<StaticImage
			className={styles.image}
			src="./assets/about-business-main.png"
			alt="Кофейня спэшл"
			objectFit="contain"
			placeholder="blurred"
			quality={90}
		/>
	),
	logoContent: (
		<StaticImage
			className={styles.logo}
			src="./assets/about-business-logo.png"
			alt="Кофейня спэшл лого"
			objectFit="contain"
			placeholder="blurred"
			quality={90}
		/>
	),
	subheading: "бустим социальное предпринимательство",
	heading: "«СПЭШЛ» автоматизация",
	textarea: "Полностью автоматизировали бизнес-процессы",
	textarea2:
		"Мы радуемся, когда узнаём о новых форматах в общепите. Поэтому готовы поделиться с первооткрывателями тем, что имеем.",
}
