import React from "react"
import classNames from "classnames"

import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css/pagination"

import AdaptiveLink from "../../../components/_V2/AdaptiveLink"

import ArrowLeftIcon from "../../../assets/_V2/svg/ArrowLeftIcon"
import ArrowRightIcon from "../../../assets/_V2/svg/ArrowRightIcon"

import styles from "./styles.module.scss"

import { getData } from "./data"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

interface Props {
	className?: string // Класс
}

export default function InNews(props: Props) {
    const localizationContext = useLocalizationContext()
    const data = getData(localizationContext.locale)

	return (
		<section className={classNames(styles.section, props.className)}>
			<h2 className={styles.heading}>О нас в СМИ</h2>

			<div className={styles.swiper__container}>
				<Swiper
					modules={[Navigation, Pagination]}
					breakpoints={{
						0: {
							slidesPerView: 1,
							slidesPerGroup: 1,
							spaceBetween: 30,
							autoHeight: true,
						},
						768: {
							slidesPerView: 3,
							slidesPerGroup: 3,
							spaceBetween: 40,
						},
						1320: {
							slidesPerView: 4,
							slidesPerGroup: 2,
							spaceBetween: 50,
						},
					}}
					navigation={{
						nextEl: ".in-news-next",
						prevEl: ".in-news-prev",
					}}
					pagination={{
						el: ".in-news-pagination",
						clickable: true,
						dynamicBullets: true,
						dynamicMainBullets: 3,
					}}
					grabCursor={true}
				>
					{data.map(card => (
						<SwiperSlide key={card.heading}>
							<AdaptiveLink
								href={card.url}
								useGatsbyLink={false}
								className={styles.card}
								target={"_blank"}
							>
								<div className={styles.card__mediaContent}>
									{card.mediaContent}
								</div>
								<div className={styles.card__content}>
									<h3 className={styles.card__heading}>{card.heading}</h3>
									<p className={styles.card__textarea}>{card.textarea}</p>
								</div>
							</AdaptiveLink>
						</SwiperSlide>
					))}

					<div
						className={classNames("in-news-pagination", styles.pagination)}
					></div>
				</Swiper>

				<button
					type="button"
					className={classNames(
						styles.navigationButton,
						styles.navigationButtonLeft,
						"in-news-prev"
					)}
				>
					<ArrowLeftIcon />
				</button>

				<button
					type="button"
					className={classNames(
						styles.navigationButton,
						styles.navigationButtonRight,
						"in-news-next"
					)}
				>
					<ArrowRightIcon />
				</button>
			</div>
		</section>
	)
}
