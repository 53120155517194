import React from "react"
import classNames from "classnames"

import AdaptiveLink from "../../_V2/AdaptiveLink"

import styles from "./styles.module.scss"

interface Props {
	className?: string
	cardClassName?: string
	data: {
		id: number // id карточки
		heading: string | JSX.Element // Заголовок
		textarea: string | JSX.Element // Описание
		mediaContent: JSX.Element // Картинка
		btn: {
			text: string // Текст кнопки
			url: string // Ссылка кнопки
			useGatsbyLink?: boolean // Использовать компонент Link?
			target?: string // target
			rel?: string // rel
		}
	}[]
}

// Компонент список карточек с половиной ширины
// Пример: /about_company/
export default function CardListHalfWidth2(props: Props) {
	const data = props.data || []

	return (
		<section className={classNames(styles.section, props.className)}>
			<div className={styles.content__row}>
				{data.map(card => (
					<React.Fragment key={card.id}>
						<div
							className={classNames(
								styles.content__column,
								props.cardClassName
							)}
						>
							<AdaptiveLink
								href={card.btn.url}
								useGatsbyLink={card.btn.useGatsbyLink}
								target={card.btn.target}
								rel={card.btn.rel}
								className={styles.card}
							>
								<div className={styles.card__content}>
									<h3 className={styles.card__heading}>{card.heading}</h3>
									<p className={styles.card__textarea}>{card.textarea}</p>

									<span className={styles.card__btn}>{card.btn.text}</span>
								</div>
								<div className={styles.card__media}>{card.mediaContent}</div>
							</AdaptiveLink>
						</div>
					</React.Fragment>
				))}
			</div>
		</section>
	)
}
