import React from "react"
import classNames from "classnames"

import AdaptiveLink from "../../_V2/AdaptiveLink"

import styles from "./styles.module.scss"

interface Props {
	className?: string
	data: {
		heading: string | JSX.Element // Заголовок
		textarea: string | JSX.Element // Описание
		btn: {
			text: string // Текст кнопки
			url: string // Ссылка кнопки
			useGatsbyLink?: boolean // Использовать компонент Link?
			target?: string // target
		}
		mediaContent: JSX.Element // Картинка
	}
}

// Компонент широкой карточки
// Пример: /about_company/
export default function CardFullWidth3(props: Props) {
	const data = props.data || {}

	return (
		<AdaptiveLink
			href={data.btn.url}
			useGatsbyLink={data.btn.useGatsbyLink}
			target={data.btn.target}
			className={classNames(styles.section, props.className)}
		>
			<div className={styles.content__row}>
				<div className={styles.content__column}>
					<h3 className={styles.heading}>{data.heading}</h3>
					<p className={styles.textarea}>{data.textarea}</p>
					{data.btn?.text && (
						<span className={styles.btn}>{data.btn.text}</span>
					)}
				</div>
				<div className={styles.content__column}>{data.mediaContent}</div>
			</div>
		</AdaptiveLink>
	)
}
