import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import styles from "./styles.module.scss";
import { Locales } from "../../../localization/types";
import classNames from "classnames";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return [
			{
				mediaContent: (
					<StaticImage
						className={classNames(styles.item__image, styles.item__image_y)}
						src="./assets/cooperation-7.png"
						alt="Клиент №1"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea:
					"Предоставляем своим клиентам бесшовную интеграцию с сервисами Яндекса.",
			},
			{
				mediaContent: (
					<StaticImage
						className={styles.item__image}
						src="./assets/cooperation-5.png"
						alt="Клиент №5"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea:
					"Предоставляем двусторонний обмен данными с системой 1С. Бухгалтерия оценит.",
			},
			{
				mediaContent: (
					<StaticImage
						className={styles.item__image}
						src="./assets/cooperation-8.png"
						alt="Клиент №5"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea:
					"Интеграция предоставляет возможность для расширения своей клиентской базы.",
				tag: "скоро",
			},
			{
				mediaContent: (
					<StaticImage
						className={styles.item__image}
						src="./assets/cooperation-9.png"
						alt="Клиент №5"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea:
					"Дополнительное онлайн-присутствие и привлечение новых клиентов.",
				tag: "скоро",
			},
		];
	}

	if (locale === "ru-BY") {
		return [
			{
				mediaContent: (
					<StaticImage
						className={styles.item__image}
						src="./assets/cooperation-5.png"
						alt="Клиент №5"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea:
					"Предоставляем двусторонний обмен данными с системой 1С. Бухгалтерия оценит.",
			},
			{
				mediaContent: (
					<StaticImage
						className={styles.item__image}
						src="./assets/cooperation-6.png"
						alt="Клиент №6"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea:
					"Состоим в кластере Сколково, участвуем в отраслевых событиях, выступаем в роли экспертов.",
			},
			{
				mediaContent: (
					<StaticImage
						className={classNames(styles.item__image, styles.item__image_y)}
						src="./assets/cooperation-7.png"
						alt="Клиент №1"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea:
					"Предоставляем своим клиентам бесшовную интеграцию с сервисами Яндекса.",
			},
			{
				mediaContent: (
					<StaticImage
						className={styles.item__image}
						src="./assets/cooperation-10.png"
						alt="Клиент №5"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea:
					"Интеграция с популярным и удобным сервисом доставки еды в Беларуси.",
				tag: "скоро",
			},
		];
	}
	return [
		{
			mediaContent: (
				<StaticImage
					className={styles.item__image}
					src="./assets/cooperation-1.png"
					alt="Клиент №1"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			textarea:
				"Предоставляем своим клиентам бесшовную интеграцию с сервисами Яндекса.",
		},
		{
			mediaContent: (
				<StaticImage
					className={styles.item__image}
					src="./assets/cooperation-2.png"
					alt="Клиент №2"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			textarea:
				"Проводим совместные вебинары. Предоставляем нативное приложение на терминал.",
		},
		{
			mediaContent: (
				<StaticImage
					className={styles.item__image}
					src="./assets/cooperation-3.png"
					alt="Клиент №3"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			textarea:
				"Помогаем предпринимателям выполнить все требования закона о маркировке товаров.",
		},
		{
			mediaContent: (
				<StaticImage
					className={styles.item__image}
					src="./assets/cooperation-4.png"
					alt="Клиент №4"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			textarea:
				"Подключаем решение для организации электронного документооборота.",
		},
		{
			mediaContent: (
				<StaticImage
					className={styles.item__image}
					src="./assets/cooperation-5.png"
					alt="Клиент №5"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			textarea:
				"Предоставляем двусторонний обмен данными с системой 1С. Бухгалтерия оценит.",
		},
		{
			mediaContent: (
				<StaticImage
					className={styles.item__image}
					src="./assets/cooperation-6.png"
					alt="Клиент №6"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			textarea:
				"Состоим в кластере Сколково, участвуем в отраслевых событиях, выступаем в роли экспертов.",
		},
	];
};
