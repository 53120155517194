import * as React from "react"
import { SVGProps } from "react"

const ArrowRightIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="32"
		height="33"
		viewBox="0 0 32 33"
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		{...props}
	>
		<path d="M13 7.5L21.9998 16.4998" strokeWidth="2" strokeLinecap="round" />
		<path d="M22 16.5L13.0001 25.4999" strokeWidth="2" strokeLinecap="round" />
	</svg>
)

export default ArrowRightIcon
