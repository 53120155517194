import React, { useState, useEffect, useRef } from "react"
import classNames from "classnames"
import debounce from "lodash.debounce"

import useWindowSize from "../../../hooks/useWindowSize"

// @ts-ignore
import { Magnifier, GlassMagnifier } from "react-image-magnifiers"
// @ts-ignore
import Image from "./assets/qr-office-5x-big.webp"
// @ts-ignore
import ImageM from "./assets/qr-office-2x-mobile.png"

import styles from "./styles.module.scss"

interface Props {
	className?: string // Класс
}

export default function TopBanner(props: Props) {
	const [showHelper, setShowHelper] = useState(false)

	let isZoomStarted = false
	const onZoomStart = () => {
		setShowHelper(false)

		isZoomStarted = true
	}

	const onZoomEnd = () => {
		setShowHelper(false)

		isZoomStarted = false
	}

	const handleScroll = () => {
		if (isZoomStarted) return
		setShowHelper(true)
	}

	useEffect(() => {
		window.addEventListener("scroll", debounce(handleScroll, 500))

		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
	}, [])

	const windowSize = useWindowSize()

	return (
		<section className={classNames(styles.section, props.className)}>
			<span className={styles.subheading}>о компании</span>
			<h1 className={styles.heading}>Играем в одной команде</h1>

			<div className={styles.content}>
				<div className={styles.content__text}>
					<p>
						Мы открываем возможности самореализации для себя и наших клиентов.
						Любим работать <br /> и создавать новое.
					</p>
				</div>

				<div className={styles.glassMagnifier__container}>
					<GlassMagnifier
						imageSrc={Image}
						imageAlt="Офис Quick Resto"
						allowOverflow={true}
						magnifierSize={"15%"}
						className={styles.glassMagnifier}
						magnifierOffsetX={-8}
						magnifierOffsetY={-5}
					/>
				</div>

				<div className={styles.magnifier__container}>
					<div>
						<Magnifier
							imageSrc={
								windowSize && windowSize.width && windowSize.width < 768
									? ImageM
									: Image
							}
							largeImageSrc={Image}
							imageAlt="Офис Quick Resto"
							onZoomStart={onZoomStart}
							onZoomEnd={onZoomEnd}
						/>
					</div>

					{showHelper && (
						<div className={styles.magnifier__helper} onClick={onZoomStart}>
							<span>
								Чтобы увеличить изображение,
								<br />
								проведите по нему двумя пальцами
							</span>
						</div>
					)}
				</div>
			</div>
		</section>
	)
}
