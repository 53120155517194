import React from "react";
import classNames from "classnames";

import { Pagination, Grid } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/grid";
import "swiper/css";
import "swiper/css/pagination";

import styles from "./styles.module.scss";

import { getData } from "./data";
import { useLocalizationContext } from "../../../localization/useLocalizationContext";

interface Props {
	className?: string; // Класс
}

export default function Cooperation(props: Props) {
	const localizationContext = useLocalizationContext();

	const data = getData(localizationContext.locale);

	return (
		<section className={classNames(styles.section, props.className)}>
			<h2 className={styles.heading}>Открыты к сотрудничеству</h2>
			<p className={styles.textarea}>
				Мы объединяем рестораторов, предпринимателей, производителей
				оборудования и поставщиков. Верим, что можем сделать опыт гостей
				приятнее и разнообразней.
			</p>

			{localizationContext.locale !== 'ru-RU' && (
				<noindex className={styles.content_kz}>
					{data.map((item, index) => (
						<div className={styles.item__kz}>
							{item.tag ? <div className={styles.tag}>{item.tag}</div> : ""}
							{item.mediaContent}
							<p className={classNames(styles.item__kz_textarea, {[styles.item__kz_text_ya]:index === 0 })}>{item.textarea}</p>
						</div>

					))}
				</noindex>
			)}

			<Swiper
				modules={[Grid, Pagination]}
				breakpoints={{
					0: {
						slidesPerView: 1,
						spaceBetween: 0,
						grid: {
							rows: 1,
							fill: "row",
						},
					},
					768: {
						slidesPerView: 2,
						spaceBetween: 40,
						grid: {
							rows: 3,
							fill: "row",
						},
					},
					1000: {
						slidesPerView: 3,
						spaceBetween: 40,
						grid: {
							rows: 2,
							fill: "row",
						},
					},
					1320: {
						slidesPerView: 2,
						spaceBetween: 50,
						grid: {
							rows: 3,
							fill: "row",
						},
					},
				}}
				pagination={{
					el: ".cooperation-pagination",
					clickable: true,
					dynamicBullets: true,
					dynamicMainBullets: 4,
				}}
				grabCursor={true}
				className={classNames({[styles.swiper_kz]: localizationContext.locale !== 'ru-RU'})}
			>
				{data.map(item => (
					<SwiperSlide key={item.textarea}>
						<div className={styles.item}>
							{item.tag ? <div className={styles.tag}>{item.tag}</div> : ""}
							{item.mediaContent}
							<p className={styles.item__textarea}>{item.textarea}</p>
						</div>

					</SwiperSlide>
				))}
				<div
					className={classNames("cooperation-pagination", styles.pagination)}
				></div>
			</Swiper>
		</section>
	);
}
