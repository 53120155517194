import React from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

import data from "./data"

interface Props {
	className?: string // Класс
}

export default function BusinessProccess(props: Props) {
	return (
		<section className={classNames(styles.section, props.className)}>
			<div className={styles.content__row}>
				<div className={styles.content__column}>
					<div className={styles.mobile}>
						<span className={styles.subheading}>{data.subheading}</span>
						<h2 className={styles.heading}>{data.heading}</h2>
					</div>
					{data.mediaContent}
					{data.logoContent}
				</div>
				<div className={styles.content__column}>
					<div className={styles.desktop}>
						<span className={styles.subheading}>{data.subheading}</span>
						<h2 className={styles.heading}>{data.heading}</h2>
					</div>

					<p className={classNames(styles.textarea, styles.textareaPrimary)}>
						<strong>{data.textarea}</strong>
					</p>
					<p className={styles.textarea}>{data.textarea2}</p>
				</div>
			</div>
		</section>
	)
}
