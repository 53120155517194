import React from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

import FryCall from "../../contacts/CtaForm/assets/FryCall"
import ArrowRightIcon from "../../../assets/_V2/svg/ArrowRightIcon"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";
import { Locales, LocalesObject } from "../../../localization/types";


interface Props {
	className?: string // Класс
}

export default function Vacancies(props: Props) {
    const localizationContext = useLocalizationContext()

    const managerVacancyCity: LocalesObject<string> = {
        'ru-RU': 'Магнитогорск',
        'ru-KZ': 'Астана',
        'kz-KZ': 'Астана',
        'ru-BY': '',
    }

    const currentManagerVacancyCity = managerVacancyCity[localizationContext.locale]

    const salesManagerVacancyCity: LocalesObject<string> = {
        'ru-RU': 'Магнитогорск',
        'ru-KZ': 'Алматы',
        'kz-KZ': 'Алматы',
        'ru-BY': '',
    }

    const currentSalesManagerVacancyCity = salesManagerVacancyCity[localizationContext.locale]

    const engineerVacancyCity: LocalesObject<string> = {
        'ru-RU': 'Магнитогорск',
        'ru-KZ': 'Алматы',
        'kz-KZ': 'Алматы',
        'ru-BY': '',
    }

		const detailText: LocalesObject<string> = {
			'ru-KZ': 'Подробнее на hh.kz',
			default: 'Подробнее на hh.ru'
		}

		const detailLink: LocalesObject<string>  = {
			'ru-KZ': "https://hh.kz/employer/1651715",
			default: 'https://hh.ru/employer/1651715',
		}

    const currentEngineerVacancyCity = engineerVacancyCity[localizationContext.locale]

	return (
		<section className={classNames(styles.section, props.className)}>
			<div className={styles.head}>
				<span className={classNames(styles.subheading, styles.subheadingMobile)}>Вакансии</span>
				<FryCall />
				<div className={styles.head__content}>
					<span className={classNames(styles.subheading, styles.subheadingDesktop)}>Вакансии</span>
					<h2 className={styles.heading}>Давай с нами!</h2>
				</div>
			</div>

			<ul className={styles.vacancies}>
				<li className={styles.vacancie}>
					<a
						href={detailLink[localizationContext.locale] || detailLink.default}
						target="_blank"
						rel="noopener noreferrer"
						className={styles.vacancie__link}
					>
						<span className={styles.vacancie__linkPosition}>
							Менеджер по работе с клиентами
						</span>
						<span className={styles.vacancie__linkCity}>{ currentManagerVacancyCity }</span>
						<ArrowRightIcon />
					</a>
				</li>
				<li className={styles.vacancie}>
					<a
						href={detailLink[localizationContext.locale] || detailLink.default}
						target="_blank"
						rel="noopener noreferrer"
						className={styles.vacancie__link}
					>
						<span className={styles.vacancie__linkPosition}>
							Sales manager/Менеджер по продаже it услуг (B2B)
						</span>
						<span className={styles.vacancie__linkCity}>{ currentSalesManagerVacancyCity }</span>
						<ArrowRightIcon />
					</a>
				</li>
				<li className={styles.vacancie}>
					<a
						href={detailLink[localizationContext.locale] || detailLink.default}
						target="_blank"
						rel="noopener noreferrer"
						className={styles.vacancie__link}
					>
						<span className={styles.vacancie__linkPosition}>
							Специалист технической поддержки
						</span>
						<span className={styles.vacancie__linkCity}>{ currentEngineerVacancyCity }</span>
						<ArrowRightIcon />
					</a>
				</li>
			</ul>

			<a
				href={detailLink[localizationContext.locale] || detailLink.default}
				target="_blank"
				rel="noopener noreferrer"
				className={styles.headhunter}
			>
				{detailText[localizationContext.locale] || detailText.default}
			</a>
		</section>
	)
}
