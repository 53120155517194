import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { externalLinks } from "../../../_V2/common/links"

import styles from "./styles.module.scss"

export const cardFullWidth3 = {
	id: 0,
	heading: "Отраслевое образование",
	textarea: (
		<span className={styles.textarea}>
			Наши эксперты&nbsp;&mdash; сертифицированные преподаватели международного
			университета гостеприимства SWISSAM. Разрабатываем программы обучения,
			бережём начинающий и&nbsp;развивающийся ресторанный бизнес от&nbsp;ошибок
			новичков, ненужных трат, обмана и&nbsp;карающего закона.
		</span>
	),
	btn: {
		text: "Подробнее",
		url: externalLinks.swissam,
		useGatsbyLink: false,
		target: "_blank",
	},
	mediaContent: (
		<StaticImage
			className={styles.first}
			src="./assets/about-company-main.png"
			alt="Отраслевое образование"
			objectFit="contain"
			placeholder="blurred"
			quality={90}
		/>
	),
}
