import React from "react"
import classNames from "classnames"

import { Pagination, Grid } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import "swiper/css/grid"
import "swiper/css"
import "swiper/css/pagination"

import styles from "./styles.module.scss"

import { getCustomerList } from "./data"
import { useLocalizationContext } from "../../../localization/useLocalizationContext";

interface Props {
	className?: string // Класс
}

export default function CustomerList(props: Props) {
	const localizationContext = useLocalizationContext();

	const listKz = getCustomerList(localizationContext.locale)

	swapPositions(listKz, 7, 2)
	swapPositions(listKz, 8, 3)

	return (
		<section className={classNames(styles.section, props.className)}>
			<h2 className={styles.heading}>10 000 заведений</h2>
			<p className={styles.textarea}>
				Ежедневно выполняют с нами миллионы заказов
			</p>

			<Swiper
				className={styles.swiper__show}
				modules={[Grid, Pagination]}
				breakpoints={{
					0: {
						slidesPerView: 2,
						slidesPerGroup: 2,
						spaceBetween: 40,
						grid: {
							rows: 2,
							fill: "row",
						},
					},
					1000: {
						slidesPerView: 7,
						spaceBetween: 45,
						grid: {
							rows: 2,
							fill: "row",
						},
					},
					1320: {
						slidesPerView: 7,
						spaceBetween: 70,
						grid: {
							rows: 2,
							fill: "row",
						},
					},
				}}
				pagination={{
					el: ".customer-list-pagination",
					clickable: true,
					// dynamicBullets: true,
					// dynamicMainBullets: 4,
				}}
				// rewind={true}
				grabCursor={true}
			>
				{listKz.map(item => (
					<SwiperSlide key={item.id}>{item.mediaContent}</SwiperSlide>
				))}

				<div
					className={classNames("swiper-pagination", "customer-list-pagination")}
				></div>
			</Swiper>

			<Swiper
				className={styles.swiper__hide}
				modules={[Grid, Pagination]}
				breakpoints={{
					0: {
						slidesPerView: 2,
						slidesPerGroup: 2,
						spaceBetween: 40,
						grid: {
							rows: 2,
							fill: "row",
						},
					},
					1000: {
						slidesPerView: 7,
						spaceBetween: 45,
						grid: {
							rows: 2,
							fill: "row",
						},
					},
					1320: {
						slidesPerView: 7,
						spaceBetween: 70,
						grid: {
							rows: 2,
							fill: "row",
						},
					},
				}}
				pagination={{
					el: ".customer-list-pagination",
					clickable: true,
					// dynamicBullets: true,
					// dynamicMainBullets: 4,
				}}
				// rewind={true}
				grabCursor={true}
			>
				{getCustomerList(localizationContext.locale).map(item => (
					<SwiperSlide key={item.id}>{item.mediaContent}</SwiperSlide>
				))}

				<div
					className={classNames("swiper-pagination", "customer-list-pagination")}
				></div>
			</Swiper>
		</section>
	)
}

const swapPositions = (array, a ,b) => {
	[array[a], array[b]] = [array[b], array[a]]
}
