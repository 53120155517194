import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import { externalLinks, pagesLinks } from "../../../_V2/common/links"

import styles from "./styles.module.scss"

export const cardListHalfWidth2 = [
	{
		id: 0,
		heading: "Справочник ресторатора",
		textarea: (
			<>
				<span className={styles.textarea__desktop}>
					Подробное руководство для
					<br />
					запуска бизнеса в общепите.
					<br />
					Доступно каждому, бесплатно.
				</span>
				<span className={styles.textarea__tabletAndMobile}>
					Подробное руководство для запуска бизнеса в общепите. Доступно
					каждому, бесплатно.
				</span>
			</>
		),
		btn: {
			text: "Подробнее",
			url: pagesLinks.restoGuide.href,
			useGatsbyLink: false,
			target: '_blank'
		},
		mediaContent: (
			<StaticImage
				className={styles.first}
				src="./assets/about-company-first.png"
				alt="Справочник ресторатора"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
	},
	{
		id: 1,
		heading: "Издаём журнал «Котёл»",
		textarea: (
			<>
				<span className={styles.textarea__desktop}>
					Развиваем информационный
					<br />
					проект для всего рынка общепита,
					<br />
					приглашаем к сотрудничеству.
				</span>
				<span className={styles.textarea__tabletAndMobile}>
					Развиваем информационный проект для всего рынка общепита, приглашаем к
					сотрудничеству.
				</span>
			</>
		),
		btn: {
			text: "Подробнее",
			url: externalLinks.kotelrestomedia,
			useGatsbyLink: false,
			target: "_blank",
			rel: "nofollow"
		},
		mediaContent: (
			<StaticImage
				className={styles.second}
				src="./assets/about-company-second.png"
				alt="Издаём журнал «Котёл»"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
	},
]
