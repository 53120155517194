import * as React from "react"

const FryCall = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		{...props}
		width="120"
		height="100"
		viewBox="0 0 120 100"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clip-path="url(#clip0_17972_3323)">
			<path
				d="M58.6902 31.4794C58.7766 30.7389 58.4643 30.0939 57.9925 30.0388C57.5208 29.9837 57.0682 30.5394 56.9818 31.28C56.8953 32.0205 57.2076 32.6655 57.6794 32.7206C58.1512 32.7757 58.6037 32.22 58.6902 31.4794Z"
				fill="#2A2D33"
			/>
			<path
				d="M51.4109 31.3954C51.5018 30.6164 51.1709 29.9377 50.6717 29.8794C50.1725 29.8211 49.6941 30.4054 49.6032 31.1843C49.5122 31.9633 49.8431 32.642 50.3423 32.7003C50.8415 32.7586 51.3199 32.1743 51.4109 31.3954Z"
				fill="#2A2D33"
			/>
			<path
				d="M23.1801 21.03C13.2501 26.19 5.75012 35.25 2.59012 46.05C1.21012 50.7 0.650124 55.7 1.05012 60.82C1.62012 68.47 4.25012 75.5 8.36012 81.34C16.4201 92.96 30.2701 100.09 45.4201 98.96C47.6601 98.79 49.8701 98.45 52.0001 97.92C57.2001 96.68 62.0001 94.45 66.2101 91.44C67.7201 90.37 69.1301 89.2 70.4701 87.95H70.4901C71.7301 86.78 72.9001 85.54 73.9901 84.24"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M63.1797 22.74C66.7197 24.83 69.9397 27.47 72.7297 30.54C77.5297 35.82 81.0397 42.45 82.5797 49.96C82.8097 51.09 82.9897 52.22 83.1297 53.35"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M51.5195 52.9999C53.9295 52.1499 55.9995 50.4799 57.1495 48.1799C58.3695 45.7299 58.4695 42.8999 58.7095 40.1399"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M35.97 41.8999C34.24 41.2799 32.8 39.7499 32.48 37.9399C32.16 36.1099 33.08 34.0799 34.76 33.3099C36.44 32.5199 38.72 33.2299 39.51 34.8899C41.41 31.7799 41.05 27.4899 38.74 24.6599C39.44 25.2299 40.66 25.0899 41.32 24.3999C42.04 23.5899 42.07 22.3499 41.7 21.3299C41.33 20.3099 40.59 19.4599 39.89 18.6699C41.08 20.0499 45.3 20.5899 47.05 20.9099C49.59 21.3999 52.19 21.9699 54.81 21.9299C57.84 21.8699 59.77 20.8199 59.77 20.8199C61.54 22.9699 61.18 27.0399 60.9 28.8799C60.56 31.0999 59.92 33.2499 59.41 35.4199"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M55.0801 39.9299C56.1201 40.7299 57.6201 40.7699 58.7101 40.1299C58.8901 40.0299 59.0401 39.8999 59.2001 39.7599C60.2901 38.7199 60.4701 36.8699 59.6101 35.6599C59.5501 35.5799 59.4901 35.4999 59.4101 35.4299C58.6101 34.4499 57.2801 33.9999 56.0301 33.6299"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M57.2695 28.6001C58.7995 28.3801 60.2095 27.4201 60.9795 26.0801"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M52.51 28.51C50.98 28.33 49.5 27.72 48.29 26.76"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M54.6203 45.79C54.1503 46.66 53.4603 47.45 52.5603 47.86C51.0303 48.56 49.1103 48.02 47.9603 46.78C46.8103 45.54 46.3903 43.76 46.5703 42.09C46.5903 41.88 46.6303 41.65 46.7803 41.5L55.0903 42.76C55.2703 43.28 54.8803 45.31 54.6303 45.78L54.6203 45.79Z"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M59.8002 20.8801C61.2702 20.2501 62.2302 18.7501 62.5402 17.1901C62.8502 15.6301 62.5902 14.0001 62.1802 12.4601C60.9902 8.00008 58.4702 3.91008 55.0302 0.830078C55.1402 2.90008 54.0302 5.00008 52.2602 6.08008C51.5702 3.69008 49.2802 1.86008 46.7902 1.71008C47.9302 3.08008 47.6202 5.15008 46.8502 6.75008C46.3802 7.73008 45.7202 8.68008 44.7502 9.17008C43.2902 9.91008 41.5402 9.45008 39.9102 9.44008C36.1302 9.43008 32.5302 12.2601 31.6602 15.9301C31.6802 15.2301 31.6902 14.4801 31.3102 13.8801C30.9302 13.2801 30.0202 12.9801 29.5102 13.4701C29.2502 13.7201 29.1702 14.1101 29.1302 14.4701C29.0302 15.5101 29.2402 16.5801 29.7202 17.5101C28.8802 16.4301 27.5402 15.3301 26.3002 15.9101C25.2202 16.4101 25.0302 17.9601 25.6002 19.0001C26.1702 20.0401 27.2402 20.6801 28.2702 21.2701C26.2102 26.4801 28.0002 32.9601 32.4502 36.3701"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M51.5201 53.1101L50.0801 59.6101"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M35.9795 41.9099C35.9595 44.5899 35.2495 48.0699 34.2695 50.5699"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M43.9805 55.2599C46.0205 59.0499 47.3005 63.2499 47.7105 67.5399C47.7305 67.4599 53.1405 58.7999 55.4405 54.3199C54.6205 53.6899 53.7905 53.0599 52.9705 52.4299"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M35.3296 46.4501C34.3996 46.4201 33.4696 46.4801 32.5596 46.6401C31.9196 46.7501 31.1796 47.0001 31.0296 47.6301C30.8796 48.2801 31.4696 48.8501 31.9996 49.2501C35.5696 51.8901 39.7096 53.8201 43.9196 55.2401"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M51.75 61.0101C53.15 61.4401 54.45 62.1901 55.52 63.1801C55.79 60.2901 55.61 57.2201 55.44 54.3201"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M43.9199 55.7301C43.2099 58.6801 43.0299 61.7601 43.3899 64.7701C40.7099 63.9201 38.0599 62.9301 35.6099 61.5701C33.1599 60.2101 30.8799 58.4501 29.1399 56.2501C28.7599 55.7701 28.3999 55.2601 28.2499 54.6701C28.1199 54.1501 28.1499 53.6001 28.2299 53.0701C28.5399 50.8401 29.6799 49.0501 31.1599 47.3501"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.45996 64.3999C7.82996 56.2399 18.1 51.1599 28.48 51.4199"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M95.0002 74.55C89.6102 78.75 83.1302 80.65 76.3702 81.09C70.5302 81.5 64.4602 80.83 58.6602 79.68C57.4502 85.01 56.2102 90.42 53.6502 95.24C53.1602 96.16 52.6102 97.05 51.9902 97.92"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M89.7996 55.81L73.2396 60.57L55.8496 55.75"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M90.0703 56.1501C90.9303 55.0801 92.9003 53.6401 92.9003 53.6401C92.9003 53.6401 91.9303 50.7601 92.1803 49.1701C92.3903 47.7701 93.1503 45.5501 95.4303 44.5601C96.7403 44.2301 98.0903 45.1301 100.02 45.4501C103.36 46.0001 104.25 46.9301 104.46 48.4601C104.37 50.9301 99.8603 50.3701 97.6003 48.6901"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M92.9297 53.71C93.6997 55.17 94.7897 56.46 96.1097 57.46"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M91.46 69.1301C95.68 68.8701 100.5 67.8101 104.82 66.1001"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M104.91 48.95C103.98 50.19 104.85 52.11 105.99 52.4C106.61 52.56 107.07 52.52 107.68 52.34C108.29 52.16 108.83 51.8 109.35 51.45C108.68 52.12 108.04 52.87 107.72 53.76C107.4 54.65 107.47 55.71 108.05 56.46C108.86 57.53 110.56 56.79 110.64 56.72C110.64 56.72 109.23 57.53 109.78 58.76C110.11 59.49 110.85 60 111.64 60.09C112.43 60.18 113.24 59.93 113.95 59.55C115.08 58.94 116.21 58.02 116.15 56.75C116.09 55.44 115.83 55.2 114.67 54.05C114.67 54.05 115.41 52.85 114.79 51.04C114.53 50.29 113.6 48.99 112.81 48.94C113.99 47.89 113.41 45.74 112.69 44.95C111.78 43.94 109.88 43.51 108.76 44.62L104.91 48.93V48.95Z"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M112.97 48.8899C111.64 49.8699 110.3 50.8499 108.97 51.8199"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M114.74 53.95C113.28 54.88 111.97 55.89 110.51 56.82"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.71027 83.16C9.58027 78.62 10.2003 74.06 11.5603 69.71"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M95.8104 68.6601C95.8904 69.6201 95.8104 71.7501 95.7204 72.7701C95.6804 73.2401 95.4804 74.4701 95.0004 74.5601C94.5104 74.6701 92.8904 73.5601 91.9104 70.9601C89.6904 65.0801 89.5404 62.0901 89.8004 55.8101"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M98.71 45.1199C98.48 43.7799 98.33 42.4399 98.22 41.1099C98.01 38.6799 97.94 36.1899 98.65 33.8799C99.37 31.5399 101.02 29.3999 103.34 28.6599C105.9 27.8299 108.9 28.9999 110.37 31.2399C111.86 33.4799 111.8 36.5699 110.41 38.8699C109.64 40.1299 108.51 41.1699 107.72 42.4499C107.02 43.5999 106.66 45.1399 107.27 46.3299"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M114.14 59.4799C117.53 59.6899 119.1 62.3199 119.1 64.6799C119.1 67.0399 118.01 69.2199 116.03 70.5199C114.35 71.6099 112.37 72.2199 109.87 71.0899C107.53 70.0299 105.86 68.3799 104.8 66.0399C102.44 60.7999 100.64 55.2999 99.4902 49.6699"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M111.03 62.1799C109.37 63.0299 108.81 64.7199 109.4 66.8699C109.9 68.6999 111.83 69.5899 113.41 69.3599"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M103.73 31.24C103.39 31.41 103.13 31.72 102.92 32.04C102.03 33.41 101.86 35.21 102.49 36.72C103.11 38.23 104.5 39.39 106.1 39.73"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M51.6295 52.97C50.0195 53.46 48.4095 53.47 46.7695 53.06"
				stroke="#2A2D33"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_17972_3323">
				<rect width="120" height="100" fill="white" />
			</clipPath>
		</defs>
	</svg>
)

export default FryCall
