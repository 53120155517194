import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"
import { Locales } from "../../../localization/types";

export const getCustomerList = (locale: Locales) => {
	if (locale === 'ru-KZ') {
		return [
			{
				id: 0,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-1.png"
						alt="Клиент №1"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 1,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-7.png"
						alt="Клиент №7"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 2,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-2.png"
						alt="Клиент №2"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 3,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-15.png"
						alt="Клиент №3"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 4,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-3.png"
						alt="Клиент №4"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 5,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-4.png"
						alt="Клиент №5"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 6,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-6.png"
						alt="Клиент №6"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 7,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-14.png"
						alt="Клиент №14"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 8,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-8.png"
						alt="Клиент №8"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 9,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-16.png"
						alt="Клиент №9"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 10,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-10.png"
						alt="Клиент №10"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 11,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-11.png"
						alt="Клиент №11"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 12,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-17.png"
						alt="Клиент №12"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 13,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-13.png"
						alt="Клиент №13"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
		]
	}

	if (locale === 'ru-BY') {
		return [
			{
				id: 0,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-1.png"
						alt="Клиент №1"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 1,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-7.png"
						alt="Клиент №7"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 2,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-18.png"
						alt="Клиент №2"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 3,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-3.png"
						alt="Клиент №3"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 4,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-4.png"
						alt="Клиент №4"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 5,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-19.png"
						alt="Клиент №5"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 6,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-6.png"
						alt="Клиент №6"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 7,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-14.png"
						alt="Клиент №14"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 8,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-20.png"
						alt="Клиент №8"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 9,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-21.png"
						alt="Клиент №9"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 10,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-10.png"
						alt="Клиент №10"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 11,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-11.png"
						alt="Клиент №11"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 12,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-12.png"
						alt="Клиент №12"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				id: 13,
				mediaContent: (
					<StaticImage
						className={styles.image}
						src="./assets/customer-22.png"
						alt="Клиент №13"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
		]
	}

	return [
		{
			id: 0,
			mediaContent: (
				<StaticImage
					className={styles.image}
					src="./assets/customer-1.png"
					alt="Клиент №1"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			id: 1,
			mediaContent: (
				<StaticImage
					className={styles.image}
					src="./assets/customer-7.png"
					alt="Клиент №7"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			id: 2,
			mediaContent: (
				<StaticImage
					className={styles.image}
					src="./assets/customer-2.png"
					alt="Клиент №2"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			id: 3,
			mediaContent: (
				<StaticImage
					className={styles.image}
					src="./assets/customer-3.png"
					alt="Клиент №3"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			id: 4,
			mediaContent: (
				<StaticImage
					className={styles.image}
					src="./assets/customer-4.png"
					alt="Клиент №4"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			id: 5,
			mediaContent: (
				<StaticImage
					className={styles.image}
					src="./assets/customer-5.png"
					alt="Клиент №5"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			id: 6,
			mediaContent: (
				<StaticImage
					className={styles.image}
					src="./assets/customer-6.png"
					alt="Клиент №6"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			id: 7,
			mediaContent: (
				<StaticImage
					className={styles.image}
					src="./assets/customer-14.png"
					alt="Клиент №14"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			id: 8,
			mediaContent: (
				<StaticImage
					className={styles.image}
					src="./assets/customer-8.png"
					alt="Клиент №8"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			id: 9,
			mediaContent: (
				<StaticImage
					className={styles.image}
					src="./assets/customer-9.png"
					alt="Клиент №9"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			id: 10,
			mediaContent: (
				<StaticImage
					className={styles.image}
					src="./assets/customer-10.png"
					alt="Клиент №10"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			id: 11,
			mediaContent: (
				<StaticImage
					className={styles.image}
					src="./assets/customer-11.png"
					alt="Клиент №11"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			id: 12,
			mediaContent: (
				<StaticImage
					className={styles.image}
					src="./assets/customer-12.png"
					alt="Клиент №12"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			id: 13,
			mediaContent: (
				<StaticImage
					className={styles.image}
					src="./assets/customer-13.png"
					alt="Клиент №13"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	]
}
