import * as React from "react"
import { SVGProps } from "react"

const ArrowLeftIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="32"
		height="33"
		viewBox="0 0 32 33"
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		{...props}
	>
		<path d="M19 7.5L10.0002 16.4998" strokeWidth="2" strokeLinecap="round" />
		<path d="M10 16.5L18.9999 25.4999" strokeWidth="2" strokeLinecap="round" />
	</svg>
)

export default ArrowLeftIcon
