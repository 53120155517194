import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {Locales} from "../../../localization/types";

export const getData = (locale: Locales) => {
    if (locale === 'ru-KZ') {
        return [
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-1.png"
                        alt="Статья №1"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "РБК",
                textarea: "Выпускники детских домов освоят ресторанное дело в...",
                url: "https://style.rbc.ru/life/6260035e9a79471d815f2058",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-5.png"
                        alt="Статья №5"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "VC.ru",
                textarea: "Облачная система автоматизации ресторанного бизнеса...",
                url: "https://vc.ru/tribuna/5292-quick-resto",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-3.png"
                        alt="Статья №3"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Риамо",
                textarea: "Цифровизация ресторанного бизнеса в России идет...",
                url:
                    "https://riamo.ru/article/603865/ekspert-strokan-tsifrovizatsiya-restorannogo-biznesa-v-rossii-idet-uspeshno",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-8.png"
                        alt="Статья №8"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Хабр",
                textarea: "Обзор Quick Resto. Как создать и автоматизировать...",
                url: "https://habr.com/ru/company/quickresto/blog/381001/",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-2.png"
                        alt="Статья №2"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Ведомости",
                textarea: "SWISSAM и Quick Resto поддерживает выпускников...",
                url:
                    "https://vedomosti-spb.ru/press_releases/2022/08/25/swissam-i-quick-resto-podderzhivaet-vipusknikov-detskih-domov--podopechnih-blagotvoritelnogo-proekta-anna-pomogaet",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-6.png"
                        alt="Статья №6"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Деловая среда",
                textarea: "Как привлечь клиентов в ресторан?",
                url: "https://dasreda.ru/learn/blog/article/399",
            },
             {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-7.png"
                        alt="Статья №7"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Rusbase",
                textarea: "Что ждет ресторанный бизнес с полным переходом на онлай...",
                url: "https://rb.ru/longread/quick-resto/",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-9.png"
                        alt="Статья №9"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Ресторановед",
                textarea: "Автоматизация: быстро, точно, удобно, надежно",
                url:
                    "http://restoranoved.ru/articles/avtomatizatsiya-bystro-tochno-udobno-nadezhno/",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-10.png"
                        alt="Статья №10"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Про бизнес",
                textarea: "Quick Resto ведет учет в кафе, барах, ресторанах за 2 руб...",
                url:
                    "https://probusiness.io/press/3717-quick-resto-avtomatizaciya-obshchestvennogo-pitaniya.html",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-11.png"
                        alt="Статья №11"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Retail.ru",
                textarea: "Как в погоне за новыми гостями не растерять постоянны...",
                url:
                    "https://www.retail.ru/articles/kak-v-pogone-za-novymi-gostyami-ne-rasteryat-postoyannykh-vo-vremya-chm-po-futbolu/",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-12.png"
                        alt="Статья №12"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Medialeaks",
                textarea: "Поймёшь ли ты ресторанный сленг?",
                url: "https://medialeaks.ru/garbage-party/",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-13.png"
                        alt="Статья №13"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Restoclub",
                textarea: "Терраса при ресторане: взгляд изнутри",
                url:
                    "https://www.restoclub.ru/review/chiefs/terrasa-pri-restorane-kak-jeto-ustroeno",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-14.png"
                        alt="Статья №14"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Про Бизнес",
                textarea: "Автоматизация в общепите: как работает сервис...",
                url:
                    "https://biz360.ru/materials/avtomatizatsiya-v-obshchepite-kak-rabotaet-servis-upravleniya-restoranami-i-kafe/?sphrase_id=66765",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-15.png"
                        alt="Статья №15"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Лайфхакер",
                textarea: "Готовы ли вы открыть свой ресторан?",
                url: "https://lifehacker.ru/quickresto-quiz/",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-16.png"
                        alt="Статья №16"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Малый бизнес",
                textarea: "Результат одной кнопкой: зачем нужна автоматизация...",
                url: "http://mbgazeta.ru/tag/restoran-avtomatizatsiya/",
            },
        ]
    }
    if (locale === 'ru-BY') {
        return [
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-1.png"
                        alt="Статья №1"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "РБК",
                textarea: "Выпускники детских домов освоят ресторанное дело в...",
                url: "https://style.rbc.ru/life/6260035e9a79471d815f2058",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-2.png"
                        alt="Статья №2"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Ведомости",
                textarea: "SWISSAM и Quick Resto поддерживает выпускников...",
                url:
                    "https://vedomosti-spb.ru/press_releases/2022/08/25/swissam-i-quick-resto-podderzhivaet-vipusknikov-detskih-domov--podopechnih-blagotvoritelnogo-proekta-anna-pomogaet",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-3.png"
                        alt="Статья №3"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Риамо",
                textarea: "Цифровизация ресторанного бизнеса в России идет...",
                url:
                    "https://riamo.ru/article/603865/ekspert-strokan-tsifrovizatsiya-restorannogo-biznesa-v-rossii-idet-uspeshno",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-4.png"
                        alt="Статья №4"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "RG.ru",
                textarea: "В Петербурге выпускник детдома получил грант на...",
                url:
                    "https://rg.ru/2022/08/26/v-peterburge-vypusknik-detdoma-poluchil-grant-na-obuchenie-v-chastnom-vuze.html",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-5.png"
                        alt="Статья №5"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "VC.ru",
                textarea: "Облачная система автоматизации ресторанного бизнеса...",
                url: "https://vc.ru/tribuna/5292-quick-resto",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-6.png"
                        alt="Статья №6"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Деловая среда",
                textarea: "Как привлечь клиентов в ресторан?",
                url: "https://dasreda.ru/learn/blog/article/399",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-7.png"
                        alt="Статья №7"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Rusbase",
                textarea: "Что ждет ресторанный бизнес с полным переходом на онлай...",
                url: "https://rb.ru/longread/quick-resto/",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-8.png"
                        alt="Статья №8"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Хабр",
                textarea: "Обзор Quick Resto. Как создать и автоматизировать...",
                url: "https://habr.com/ru/company/quickresto/blog/381001/",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-9.png"
                        alt="Статья №9"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Ресторановед",
                textarea: "Автоматизация: быстро, точно, удобно, надежно",
                url:
                    "http://restoranoved.ru/articles/avtomatizatsiya-bystro-tochno-udobno-nadezhno/",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-10.png"
                        alt="Статья №10"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Про бизнес",
                textarea: "Quick Resto ведет учет в кафе, барах, ресторанах за 2 руб...",
                url:
                    "https://probusiness.io/press/3717-quick-resto-avtomatizaciya-obshchestvennogo-pitaniya.html",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-11.png"
                        alt="Статья №11"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Retail.ru",
                textarea: "Как в погоне за новыми гостями не растерять постоянны...",
                url:
                    "https://www.retail.ru/articles/kak-v-pogone-za-novymi-gostyami-ne-rasteryat-postoyannykh-vo-vremya-chm-po-futbolu/",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-12.png"
                        alt="Статья №12"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Medialeaks",
                textarea: "Поймёшь ли ты ресторанный сленг?",
                url: "https://medialeaks.ru/garbage-party/",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-13.png"
                        alt="Статья №13"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Restoclub",
                textarea: "Терраса при ресторане: взгляд изнутри",
                url:
                    "https://www.restoclub.ru/review/chiefs/terrasa-pri-restorane-kak-jeto-ustroeno",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-14.png"
                        alt="Статья №14"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Про Бизнес",
                textarea: "Автоматизация в общепите: как работает сервис...",
                url:
                    "https://biz360.ru/materials/avtomatizatsiya-v-obshchepite-kak-rabotaet-servis-upravleniya-restoranami-i-kafe/?sphrase_id=66765",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-15.png"
                        alt="Статья №15"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Лайфхакер",
                textarea: "Готовы ли вы открыть свой ресторан?",
                url: "https://lifehacker.ru/quickresto-quiz/",
            },
            {
                mediaContent: (
                    <StaticImage
                        src="./assets/innews-article-16.png"
                        alt="Статья №16"
                        objectFit="contain"
                        placeholder="blurred"
                        quality={100}
                    />
                ),
                heading: "Малый бизнес",
                textarea: "Результат одной кнопкой: зачем нужна автоматизация...",
                url: "http://mbgazeta.ru/tag/restoran-avtomatizatsiya/",
            },
        ]
    }
    return [
        {
            mediaContent: (
                <StaticImage
                    src="./assets/innews-article-1.png"
                    alt="Статья №1"
                    objectFit="contain"
                    placeholder="blurred"
                    quality={100}
                />
            ),
            heading: "РБК",
            textarea: "Выпускники детских домов освоят ресторанное дело в...",
            url: "https://style.rbc.ru/life/6260035e9a79471d815f2058",
        },
        {
            mediaContent: (
                <StaticImage
                    src="./assets/innews-article-2.png"
                    alt="Статья №2"
                    objectFit="contain"
                    placeholder="blurred"
                    quality={100}
                />
            ),
            heading: "Ведомости",
            textarea: "SWISSAM и Quick Resto поддерживает выпускников...",
            url:
                "https://vedomosti-spb.ru/press_releases/2022/08/25/swissam-i-quick-resto-podderzhivaet-vipusknikov-detskih-domov--podopechnih-blagotvoritelnogo-proekta-anna-pomogaet",
        },
        {
            mediaContent: (
                <StaticImage
                    src="./assets/innews-article-3.png"
                    alt="Статья №3"
                    objectFit="contain"
                    placeholder="blurred"
                    quality={100}
                />
            ),
            heading: "Риамо",
            textarea: "Цифровизация ресторанного бизнеса в России идет...",
            url:
                "https://riamo.ru/article/603865/ekspert-strokan-tsifrovizatsiya-restorannogo-biznesa-v-rossii-idet-uspeshno",
        },
        {
            mediaContent: (
                <StaticImage
                    src="./assets/innews-article-4.png"
                    alt="Статья №4"
                    objectFit="contain"
                    placeholder="blurred"
                    quality={100}
                />
            ),
            heading: "RG.ru",
            textarea: "В Петербурге выпускник детдома получил грант на...",
            url:
                "https://rg.ru/2022/08/26/v-peterburge-vypusknik-detdoma-poluchil-grant-na-obuchenie-v-chastnom-vuze.html",
        },
        {
            mediaContent: (
                <StaticImage
                    src="./assets/innews-article-5.png"
                    alt="Статья №5"
                    objectFit="contain"
                    placeholder="blurred"
                    quality={100}
                />
            ),
            heading: "VC.ru",
            textarea: "Облачная система автоматизации ресторанного бизнеса...",
            url: "https://vc.ru/tribuna/5292-quick-resto",
        },
        {
            mediaContent: (
                <StaticImage
                    src="./assets/innews-article-6.png"
                    alt="Статья №6"
                    objectFit="contain"
                    placeholder="blurred"
                    quality={100}
                />
            ),
            heading: "Деловая среда",
            textarea: "Как привлечь клиентов в ресторан?",
            url: "https://dasreda.ru/learn/blog/article/399",
        },
        {
            mediaContent: (
                <StaticImage
                    src="./assets/innews-article-7.png"
                    alt="Статья №7"
                    objectFit="contain"
                    placeholder="blurred"
                    quality={100}
                />
            ),
            heading: "Rusbase",
            textarea: "Что ждет ресторанный бизнес с полным переходом на онлай...",
            url: "https://rb.ru/longread/quick-resto/",
        },
        {
            mediaContent: (
                <StaticImage
                    src="./assets/innews-article-8.png"
                    alt="Статья №8"
                    objectFit="contain"
                    placeholder="blurred"
                    quality={100}
                />
            ),
            heading: "Хабр",
            textarea: "Обзор Quick Resto. Как создать и автоматизировать...",
            url: "https://habr.com/ru/company/quickresto/blog/381001/",
        },
        {
            mediaContent: (
                <StaticImage
                    src="./assets/innews-article-9.png"
                    alt="Статья №9"
                    objectFit="contain"
                    placeholder="blurred"
                    quality={100}
                />
            ),
            heading: "Ресторановед",
            textarea: "Автоматизация: быстро, точно, удобно, надежно",
            url:
                "http://restoranoved.ru/articles/avtomatizatsiya-bystro-tochno-udobno-nadezhno/",
        },
        {
            mediaContent: (
                <StaticImage
                    src="./assets/innews-article-10.png"
                    alt="Статья №10"
                    objectFit="contain"
                    placeholder="blurred"
                    quality={100}
                />
            ),
            heading: "Про бизнес",
            textarea: "Quick Resto ведет учет в кафе, барах, ресторанах за 2 руб...",
            url:
                "https://probusiness.io/press/3717-quick-resto-avtomatizaciya-obshchestvennogo-pitaniya.html",
        },
        {
            mediaContent: (
                <StaticImage
                    src="./assets/innews-article-11.png"
                    alt="Статья №11"
                    objectFit="contain"
                    placeholder="blurred"
                    quality={100}
                />
            ),
            heading: "Retail.ru",
            textarea: "Как в погоне за новыми гостями не растерять постоянны...",
            url:
                "https://www.retail.ru/articles/kak-v-pogone-za-novymi-gostyami-ne-rasteryat-postoyannykh-vo-vremya-chm-po-futbolu/",
        },
        {
            mediaContent: (
                <StaticImage
                    src="./assets/innews-article-12.png"
                    alt="Статья №12"
                    objectFit="contain"
                    placeholder="blurred"
                    quality={100}
                />
            ),
            heading: "Medialeaks",
            textarea: "Поймёшь ли ты ресторанный сленг?",
            url: "https://medialeaks.ru/garbage-party/",
        },
        {
            mediaContent: (
                <StaticImage
                    src="./assets/innews-article-13.png"
                    alt="Статья №13"
                    objectFit="contain"
                    placeholder="blurred"
                    quality={100}
                />
            ),
            heading: "Restoclub",
            textarea: "Терраса при ресторане: взгляд изнутри",
            url:
                "https://www.restoclub.ru/review/chiefs/terrasa-pri-restorane-kak-jeto-ustroeno",
        },
        {
            mediaContent: (
                <StaticImage
                    src="./assets/innews-article-14.png"
                    alt="Статья №14"
                    objectFit="contain"
                    placeholder="blurred"
                    quality={100}
                />
            ),
            heading: "Про Бизнес",
            textarea: "Автоматизация в общепите: как работает сервис...",
            url:
                "https://biz360.ru/materials/avtomatizatsiya-v-obshchepite-kak-rabotaet-servis-upravleniya-restoranami-i-kafe/?sphrase_id=66765",
        },
        {
            mediaContent: (
                <StaticImage
                    src="./assets/innews-article-15.png"
                    alt="Статья №15"
                    objectFit="contain"
                    placeholder="blurred"
                    quality={100}
                />
            ),
            heading: "Лайфхакер",
            textarea: "Готовы ли вы открыть свой ресторан?",
            url: "https://lifehacker.ru/quickresto-quiz/",
        },
        {
            mediaContent: (
                <StaticImage
                    src="./assets/innews-article-16.png"
                    alt="Статья №16"
                    objectFit="contain"
                    placeholder="blurred"
                    quality={100}
                />
            ),
            heading: "Малый бизнес",
            textarea: "Результат одной кнопкой: зачем нужна автоматизация...",
            url: "http://mbgazeta.ru/tag/restoran-avtomatizatsiya/",
        },
    ]
}
