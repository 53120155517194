import React from "react"
import classNames from "classnames"

import { Swiper, SwiperSlide } from "swiper/react"

import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"

import Backdrop from "./assets/Backdrop"

interface Props {
	className?: string // Класс
}

export default function YouTube(props: Props) {
	return (
		<section className={classNames(styles.section, props.className)}>
			<h2 className={styles.heading}>Участвуем в выставках</h2>

			<Swiper
				breakpoints={{
					0: {
						slidesPerView: 2.5,
						spaceBetween: 30,
					},
					425: {
						slidesPerView: 3.5,
						spaceBetween: 30,
					},
					768: {
						slidesPerView: 7,
						spaceBetween: 40,
					},
					1320: {
						slidesPerView: 7,
						spaceBetween: 40,
					},
				}}
				grabCursor={true}
			>
				<SwiperSlide className={styles.slide1}>
					<StaticImage
						className={styles.image}
						src="./assets/youtube-3.png"
						alt="Лого №3"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</SwiperSlide>
				<SwiperSlide className={styles.slide2}>
					<StaticImage
						className={styles.image}
						src="./assets/youtube-1.png"
						alt="Лого №1"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</SwiperSlide>
				<SwiperSlide className={styles.slide3}>
					<StaticImage
						className={styles.image}
						src="./assets/youtube-2.png"
						alt="Лого №2"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</SwiperSlide>

				<SwiperSlide className={styles.slide4}>
					<StaticImage
						className={styles.image}
						src="./assets/youtube-4.png"
						alt="Лого №4"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</SwiperSlide>
				<SwiperSlide className={styles.slide5}>
					<StaticImage
						className={styles.image}
						src="./assets/youtube-5.png"
						alt="Лого №5"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</SwiperSlide>
				<SwiperSlide className={styles.slide6}>
					<StaticImage
						className={styles.image}
						src="./assets/youtube-6.png"
						alt="Лого №6"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</SwiperSlide>
				<SwiperSlide className={styles.slide7}>
					<StaticImage
						className={styles.image}
						src="./assets/youtube-7.png"
						alt="Лого №7"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</SwiperSlide>
			</Swiper>

			<div className={styles.iframe}>
				<iframe
					src="https://www.youtube.com/embed/nScujqR-_JU"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				></iframe>
			</div>

			<Backdrop className={styles.backdrop1} />
			<Backdrop className={styles.backdrop2} />
		</section>
	)
}
