import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'

import TopBanner from '../pagesSections/about_company/TopBanner'
import Diagram from '../pagesSections/about_company/Diagram'
import BusinessProccess from '../pagesSections/about_company/BusinessProccess'
import ThreeCards from '../pagesSections/about_company/ThreeCards'
import CustomerList from '../pagesSections/about_company/CustomerList'
import YouTube from '../pagesSections/about_company/YouTube'
import Vacancies from '../pagesSections/about_company/Vacancies'
import Cooperation from '../pagesSections/about_company/Cooperation'
import InNews from '../pagesSections/about_company/InNews'

import pageStyles from '../styles/_V2/pagesStyles/styles.module.scss'
import styles from './styles/_V2/about_company.module.scss'
import { useLocalizationContext } from '../localization/useLocalizationContext'
import { externalLinks } from '../pages-data/_V2/common/links'

import Pres from '../pagesSections/about_company/TopBanner/assets/qr-office-big.webp';

export default function PageAboutCompany() {
  const localizationContext = useLocalizationContext()

  const metaTitle = {
    'ru-RU': 'О компании Quick Resto — эксперте в сфере ресторанного бизнеса',
    'ru-KZ': 'О компании Quick Resto — эксперте в сфере ресторанного бизнеса | Казахстан',
    'kz-KZ': 'О компании Quick Resto — эксперте в сфере ресторанного бизнеса | Казахстан',
    'ru-BY': 'О компании Quick Resto — эксперте в сфере ресторанного бизнеса | Беларусь',
  }

  const currentTitle = metaTitle[localizationContext.locale]

  const metaUrl = {
    'ru-RU': `${externalLinks.quickrestoRU.href}about_company/`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}about_company/`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}about_company/`,
    'ru-BY': `${externalLinks.quickrestoBY.href}about_company/`,
  }

  const currentCanonicalLink = metaUrl[localizationContext.locale]

  const metaDescription = {
    'ru-RU': 'Quick Resto стремится сделать систему автоматизации удобной и доступной для всех, кто работает в сфере обслуживания, использовать накопленный опыт и знания IT-технологий для создания инновационного продукта.',
    'ru-KZ': 'Quick Resto стремится сделать систему автоматизации удобной и доступной для всех, кто работает в сфере обслуживания, использовать накопленный опыт и знания IT-технологий для создания инновационного продукта. Автоматизируй бизнес в Казахстане',
    'kz-KZ': 'Quick Resto стремится сделать систему автоматизации удобной и доступной для всех, кто работает в сфере обслуживания, использовать накопленный опыт и знания IT-технологий для создания инновационного продукта. Автоматизируй бизнес в Казахстане',
    'ru-BY': 'Quick Resto стремится сделать систему автоматизации удобной и доступной для всех, кто работает в сфере обслуживания, использовать накопленный опыт и знания IT-технологий для создания инновационного продукта. Автоматизируй бизнес в Беларуси',
  }

  const ogImage = {
    'ru-RU': `${externalLinks.quickrestoRU.href}${Pres}`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}${Pres}`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}${Pres}`,
    'ru-BY': `${externalLinks.quickrestoBY.href}${Pres}`,
  }

  const metaTags = [
    {
      name: 'description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:url',
      content: metaUrl[localizationContext.locale],
    },
    {
      property: 'og:title',
      content: currentTitle,
    },
    {
      property: 'og:description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: ogImage[localizationContext.locale]
    },
  ]

  return (
    <DefaultLayout
      key={localizationContext.locale}
      title={currentTitle}
      metaTags={metaTags}
      mainWrapperClassName={classNames(
			  pageStyles.pageWrapper,
			  pageStyles.pageGray,
			  pageStyles.aboutCompanyPage,
      )}
      linkCanonical={currentCanonicalLink}
    >
      <TopBanner className={styles.about__one} />

      <Diagram className={styles.about__two} />

      <ThreeCards className={styles.about__three} />

      <BusinessProccess className={styles.about__four} />

      <InNews className={styles.about__five} />

      <Cooperation className={styles.about__six} />

      <YouTube className={styles.about__seven} />

      <CustomerList className={styles.about__eight} />

      { localizationContext.locale !== 'ru-BY' ? <Vacancies className={styles.about__nine} /> : '' }
    </DefaultLayout>
  )
}
