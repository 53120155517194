import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Locales } from "../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === "ru-KZ") {
		return [
			{
				mediaContent: (
					<StaticImage
						src="./assets/diagram-1.png"
						alt="Основание компании"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea: "Основание компании",
				textarea_m: (
					<>
						Основание
						<br />
						компании
					</>
				),
				year: "2013",
			},
			{
				mediaContent: (
					<StaticImage
						src="./assets/diagram-2.png"
						alt="Стали резидентом «Сколково»"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea: (
					<>
						Стали
						<br />
						резидентом
						<br />
						«Сколково»
					</>
				),
				textarea_m: (
					<>
						Стали резидентом
						<br />
						«Сколково»
					</>
				),
				year: "2014",
			},
			{
				mediaContent: (
					<StaticImage
						src="./assets/diagram-3.png"
						alt="Вышли на рынок СНГ"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea: (
					<>
						Вышли на
						<br />
						рынок СНГ
					</>
				),
				textarea_m: (
					<>
						Вышли
						<br />
						на рынок СНГ
					</>
				),
				year: "2015",
			},
			{
				mediaContent: (
					<StaticImage
						src="./assets/diagram-4.png"
						alt="Совместное предприятие с «1С»"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea: (
					<>
						Совместное
						<br />
						предприятие с «1С»
					</>
				),
				textarea_m: (
					<>
						Quick Resto стала
						<br />
						частью «1С»
					</>
				),
				year: "2016",
			},
			{
				mediaContent: (
					<StaticImage
						src="./assets/diagram-5.png"
						alt="Запустились в 8 странах"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea: (
					<>
						Запустились <br />в 8 странах
					</>
				),
				textarea_m: (
					<>
						Запустились <br />в 8 странах
					</>
				),
				year: "2017",
			},
			{
				mediaContent: (
					<StaticImage
						src="./assets/diagram-6.png"
						alt="5000+ активных клиентов"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea: (
					<>
						5000+ активных
						<br />
						клиентов
					</>
				),
				textarea_m: (
					<>
						5000+ активных
						<br />
						клиентов
					</>
				),
				year: "2019",
			},
			{
				mediaContent: (
					<StaticImage
						src="./assets/diagram-7.png"
						alt="Запустили сервисы для развития бизнеса"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea: (
					<>
						Запустили
						<br />
						сервисы
						<br />
						для развития
						<br />
						бизнеса
					</>
				),
				textarea_m: (
					<>
						Запустили сервисы
						<br />
						для развития бизнеса
					</>
				),
				year: "2020",
			},
			{
				mediaContent: (
					<StaticImage
						src="./assets/diagram-8.png"
						alt="Работаем на всех платформах"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea: (
					<>
						Работаем на всех
						<br />
						платформах
					</>
				),
				textarea_m: (
					<>
						Работаем на всех
						<br />
						платформах
					</>
				),
				year: "2021",
			},
			{
				mediaContent: (
					<StaticImage
						src="./assets/diagram-9.png"
						alt="Преподаём в Swissam"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea: (
					<ul>
						<li>
							Преподаём <br /> в Swissam
						</li>
						<li>
							Поддерживаем <br /> социальные проекты
						</li>
						<li>
							Обновили видение <br /> компании. Запустили <br />
							образовательные <br /> проекты
						</li>
					</ul>
				),
				textarea_m: (
					<ul>
						<li>Преподаём в Swissam</li>
						<li>
							Поддерживаем социальные
							<br />
							проекты
						</li>
						<li>
							Обновили видение компании.
							<br />
							Запустили образовательные
							<br />
							проекты
						</li>
					</ul>
				),
				year: "2022",
			},
			{
				mediaContent: (
					<StaticImage
						src="./assets/diagram-10.png"
						alt="Преподаём в Swissam"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea: (
					<>
						Открыли<br />
						представительство<br />
						в Казахстане
					</>
				),
				textarea_m: (
					<>
						Открыли<br />
						представительство<br />
						в Казахстане
					</>
				),
				year: "2023",
			},
		];
	}

	if (locale === "ru-BY") {
		return [
			{
				mediaContent: (
					<StaticImage
						src="./assets/diagram-1.png"
						alt="Основание компании"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea: "Основание компании",
				textarea_m: (
					<>
						Основание
						<br />
						компании
					</>
				),
				year: "2013",
			},
			{
				mediaContent: (
					<StaticImage
						src="./assets/diagram-2.png"
						alt="Стали резидентом «Сколково»"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea: (
					<>
						Стали
						<br />
						резидентом
						<br />
						«Сколково»
					</>
				),
				textarea_m: (
					<>
						Стали резидентом
						<br />
						«Сколково»
					</>
				),
				year: "2014",
			},
			{
				mediaContent: (
					<StaticImage
						src="./assets/diagram-3.png"
						alt="Вышли на рынок СНГ"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea: (
					<>
						Вышли на
						<br />
						рынок СНГ
					</>
				),
				textarea_m: (
					<>
						Вышли
						<br />
						на рынок СНГ
					</>
				),
				year: "2015",
			},
			{
				mediaContent: (
					<StaticImage
						src="./assets/diagram-4.png"
						alt="Совместное предприятие с «1С»"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea: (
					<>
						Совместное
						<br />
						предприятие с «1С»
					</>
				),
				textarea_m: (
					<>
						Quick Resto стала
						<br />
						частью «1С»
					</>
				),
				year: "2016",
			},
			{
				mediaContent: (
					<StaticImage
						src="./assets/diagram-5.png"
						alt="Запустились в 8 странах"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea: (
					<>
						Запустились <br />в 8 странах
					</>
				),
				textarea_m: (
					<>
						Запустились <br />в 8 странах
					</>
				),
				year: "2017",
			},
			{
				mediaContent: (
					<StaticImage
						src="./assets/diagram-6.png"
						alt="5000+ активных клиентов"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea: (
					<>
						5000+ активных
						<br />
						клиентов
					</>
				),
				textarea_m: (
					<>
						5000+ активных
						<br />
						клиентов
					</>
				),
				year: "2019",
			},
			{
				mediaContent: (
					<StaticImage
						src="./assets/diagram-7.png"
						alt="Запустили сервисы для развития бизнеса"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea: (
					<>
						Запустили
						<br />
						сервисы
						<br />
						для развития
						<br />
						бизнеса
					</>
				),
				textarea_m: (
					<>
						Запустили сервисы
						<br />
						для развития бизнеса
					</>
				),
				year: "2020",
			},
			{
				mediaContent: (
					<StaticImage
						src="./assets/diagram-8.png"
						alt="Работаем на всех платформах"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea: (
					<>
						Работаем на всех
						<br />
						платформах
					</>
				),
				textarea_m: (
					<>
						Работаем на всех
						<br />
						платформах
					</>
				),
				year: "2021",
			},
			{
				mediaContent: (
					<StaticImage
						src="./assets/diagram-9.png"
						alt="Преподаём в Swissam"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				textarea: (
					<ul>
						<li>
							Преподаём <br /> в Swissam
						</li>
						<li>
							Поддерживаем <br /> социальные проекты
						</li>
						<li>
							Обновили видение <br /> компании. Запустили <br />
							образовательные <br /> проекты
						</li>
					</ul>
				),
				textarea_m: (
					<ul>
						<li>Преподаём в Swissam</li>
						<li>
							Поддерживаем социальные
							<br />
							проекты
						</li>
						<li>
							Обновили видение компании.
							<br />
							Запустили образовательные
							<br />
							проекты
						</li>
					</ul>
				),
				year: "2022",
			},
		];
	}

	return [
		{
			mediaContent: (
				<StaticImage
					src="./assets/diagram-1.png"
					alt="Основание компании"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			textarea: "Основание компании",
			textarea_m: (
				<>
					Основание
					<br />
					компании
				</>
			),
			year: "2013",
		},
		{
			mediaContent: (
				<StaticImage
					src="./assets/diagram-2.png"
					alt="Стали резидентом «Сколково»"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			textarea: (
				<>
					Стали
					<br />
					резидентом
					<br />
					«Сколково»
				</>
			),
			textarea_m: (
				<>
					Стали резидентом
					<br />
					«Сколково»
				</>
			),
			year: "2014",
		},
		{
			mediaContent: (
				<StaticImage
					src="./assets/diagram-3.png"
					alt="Вышли на рынок СНГ"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			textarea: (
				<>
					Вышли на
					<br />
					рынок СНГ
				</>
			),
			textarea_m: (
				<>
					Вышли
					<br />
					на рынок СНГ
				</>
			),
			year: "2015",
		},
		{
			mediaContent: (
				<StaticImage
					src="./assets/diagram-4.png"
					alt="Совместное предприятие с «1С»"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			textarea: (
				<>
					Совместное
					<br />
					предприятие с «1С»
				</>
			),
			textarea_m: (
				<>
					Quick Resto стала
					<br />
					частью «1С»
				</>
			),
			year: "2016",
		},
		{
			mediaContent: (
				<StaticImage
					src="./assets/diagram-5.png"
					alt="Запустились в 8 странах"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			textarea: (
				<>
					Запустились <br />в 8 странах
				</>
			),
			textarea_m: (
				<>
					Запустились <br />в 8 странах
				</>
			),
			year: "2017",
		},
		{
			mediaContent: (
				<StaticImage
					src="./assets/diagram-6.png"
					alt="5000+ активных клиентов"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			textarea: (
				<>
					5000+ активных
					<br />
					клиентов
				</>
			),
			textarea_m: (
				<>
					5000+ активных
					<br />
					клиентов
				</>
			),
			year: "2019",
		},
		{
			mediaContent: (
				<StaticImage
					src="./assets/diagram-7.png"
					alt="Запустили сервисы для развития бизнеса"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			textarea: (
				<>
					Запустили
					<br />
					сервисы
					<br />
					для развития
					<br />
					бизнеса
				</>
			),
			textarea_m: (
				<>
					Запустили сервисы
					<br />
					для развития бизнеса
				</>
			),
			year: "2020",
		},
		{
			mediaContent: (
				<StaticImage
					src="./assets/diagram-8.png"
					alt="Работаем на всех платформах"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			textarea: (
				<>
					Работаем на всех
					<br />
					платформах
				</>
			),
			textarea_m: (
				<>
					Работаем на всех
					<br />
					платформах
				</>
			),
			year: "2021",
		},
		{
			mediaContent: (
				<StaticImage
					src="./assets/diagram-9.png"
					alt="Преподаём в Swissam"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			textarea: (
				<ul>
					<li>
						Преподаём <br /> в Swissam
					</li>
					<li>
						Поддерживаем <br /> социальные проекты
					</li>
					<li>
						Обновили видение <br /> компании. Запустили <br />
						образовательные <br /> проекты
					</li>
				</ul>
			),
			textarea_m: (
				<ul>
					<li>Преподаём в Swissam</li>
					<li>
						Поддерживаем социальные
						<br />
						проекты
					</li>
					<li>
						Обновили видение компании.
						<br />
						Запустили образовательные
						<br />
						проекты
					</li>
				</ul>
			),
			year: "2022",
		},
	];
};
