import React from "react"
import classNames from "classnames"

import CardFullWidth3 from "../../../components/_V3/CardFullWidth3"
import CardListHalfWidth2 from "../../../components/_V3/CardListHalfWidth2"

import styles from "./styles.module.scss"

import { cardFullWidth3 } from "../../../pages-data/_V3/about_company/CardFullWidth3/data"
import { cardListHalfWidth2 } from "../../../pages-data/_V3/about_company/CardListHalfWidth2/data"

interface Props {
	className?: string // Класс
}

export default function ThreeCards(props: Props) {
	return (
		<>
			<section className={classNames(styles.section, props.className)}>
				<h2 className={styles.heading}>Поддержим в смелых начинаниях</h2>
				<p className={styles.textarea}>
					<span className={styles.tablet}>
						Мы бережем начинающий и развивающийся ресторанный бизнес от ошибок
						<br />
						новичков, ненужных трат, обмана и карающего закона.
					</span>
					<span className={styles.mobile}>
						Мы бережем начинающий и развивающийся ресторанный бизнес от ошибок
						новичков, ненужных трат, обмана и карающего закона.
					</span>
				</p>
			</section>
			<CardFullWidth3 data={cardFullWidth3} className={styles.hover} />

			<CardListHalfWidth2 data={cardListHalfWidth2} cardClassName={styles.hover} />
		</>
	)
}
